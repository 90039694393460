<template>
	<div class="border-8 rounded-full shadow-xl border-primary">
	              <nuxt-img
	                format="webp"
	                quality="50"
	                src="/img/blog-author.jpg"
	                class="h-48 rounded-full sm:h-56"
	                alt="author"
	              />
	            </div>
</template>
