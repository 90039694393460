<template>
  <div class="bg-white">
    <h2 class="text-4xl font-semibold text-center uppercase font-header text-primary sm:text-5xl lg:text-6xl">
      Contact Me
    </h2>
    <h4 class="pt-6 text-xl font-medium text-center text-black font-header sm:text-2xl lg:text-3xl">
      Have Any Questions?
    </h4>
    <div class="w-full pt-5 mx-auto text-center sm:w-2/3 lg:pt-6">
      <p class="font-body text-grey-10">
        If you need consulting work, are interested in employing me for A/V or other projects, or just want to say hi, I'd love to hear from you.
      </p>
    </div>
    <UForm
      ref="contactForm"
      name="contactForm"
      class="w-full py-10 mx-auto sm:w-3/4 px-4 sm:px-6"
      :state="state"
      :schema="schema"
      @submit="submitEmail"
    >
      <div class="flex flex-col md:flex-row gap-4 md:gap-6">
        <UFormGroup
          class="w-full md:pb-0 md:w-1/2"
          name="name"
        >
          <UInput
            id="name"
            v-model="state.name"
            color="primary"
            placeholder="Name"
            size="xl"
            required
            :padding="false"
          />
        </UFormGroup>
        <UFormGroup
          class="w-full md:pb-0 md:w-1/2"
          name="email"
          required
        >
          <UInput
            id="email"
            v-model="state.email"
            color="primary"
            placeholder="Email Address"
            required
            size="xl"
          />
        </UFormGroup>
      </div>
      <UFormGroup
        class="w-full py-4 md:py-6"
        name="message"
        required
      >
        <UTextarea
          id="message"
          v-model="state.message"
          color="primary"
          placeholder="Message..."
          required
          size="xl"
          :rows="5"
          autoresize
        />
      </UFormGroup>
      <div class="flex items-center justify-between flex-col md:flex-row gap-4 md:gap-6">
        <ClientOnly>
          <div class="bg-white text-black">
            <altcha-widget
              ref="altchaWidget"
              challengeurl="/api/get_challenge"
              name="altcha_payload"
              hidelogo
              hidefooter
            />
          </div>
        </ClientOnly>
        <div class="justify-self-end">
          <UButton
            type="submit"
          >
            <div class="text-white">
              Send
            </div>
            <i class="relative text-white text-3xl bx bx-chevron-right -right-2" />
          </UButton>
        </div>
      </div>
    </UForm>
    <UModal
      v-model="errorModal.visible"
    >
      <div class="p-4">
        <h3 class="text-2xl font-semibold text-center text-red font-header">
          {{ errorModal.title }}
        </h3>
        <p class="pt-4 text-lg font-medium text-center text-black font-body">
          {{ errorModal.message }}
        </p>
        <button
          class="w-full py-2 mt-4 text-lg font-semibold text-center text-white bg-red-500 rounded-md font-body"
          @click="errorModal.visible = false"
        >
          Close
        </button>
      </div>
    </UModal>
  </div>
</template>

<script setup lang="ts">
// eslint-disable @typescript-eslint/no-explicit-any
import { z } from 'zod'
import type { FormSubmitEvent, Form } from '#ui/types'

const schema = z.object({
  email: z.string().email(),
  name: z.string().min(3).max(48),
  message: z.string().min(10).max(1024),
  contactChallengeToken: z.string(),
})

type Schema = z.output<typeof schema>

const state = reactive({
  email: '',
  name: '',
  message: '',
  contactChallengeToken: '',
})

const errorModal = reactive({
  visible: false,
  title: 'Error',
  message: 'An error occurred while sending your message. Please try again later.',
})

const contactForm = ref < Form<Schema> | undefined>(undefined)
const toast = useToast()
const altchaWidget = ref<HTMLElement | null>(null)

// // eslint-disable-next-line @typescript-eslint/no-explicit-any
// const altchaVerified = (data: any): void => {
//   console.log('Verified:', data)
//   if (data.type == 'verified') {
//     contactSendEnabled.value = true
//   }
// }
// // eslint-disable-next-line @typescript-eslint/no-explicit-any
// const altchaServerVerification = (data: any): void => {
//   console.log('Server:', data)
// }

// onMounted(() => {
//   nextTick(() => {
//     if (altchaWidget.value) {
//       altchaWidget.value?.addEventListener('verified', altchaVerified)
//       altchaWidget.value?.addEventListener('serververification', altchaServerVerification)
//     }
//   })
// },
// )

async function submitEmail(event: FormSubmitEvent<Schema>) {
  console.log('Submit:', event)

  const contactBody = JSON.stringify({
    email: event.data.email,
    name: event.data.name,
    message: event.data.message,
    contactChallengeToken: (altchaWidget.value?.querySelector('input[name=altcha_payload]') as HTMLInputElement)?.value,
  })
  const response = await $fetch('/api/contact/', {
    method: 'POST',
    body: contactBody,
    ignoreResponseError: true,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) as any

  if (response.statusCode === 422) {
    try {
      const errors = response.data.issues.map((err:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { message: any, path: any[] }) => ({
        message: err.message,
        path: Array.isArray(err.path) ? err.path.join('.') : err.path,
      }))
      contactForm.value?.setErrors(errors)
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    catch (error: any) {
      errorModal.title = 'Validation Error'
      errorModal.message = `An error occurred while validating your message. Please try again later. ${error.message}`
      errorModal.visible = true
    }
  }
  else if (response.statusCode !== 200) {
    errorModal.title = 'Submission Error'
    errorModal.message = `${response.statusCode}: ${response.error}`
    errorModal.visible = true
    contactForm.value?.clear()
  }
  else {
    toast.add({
      title: 'Message sent successfully!' })
    contactForm.value?.clear()
  }
}
</script>
