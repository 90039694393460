<template>
  <div>
    <div>
      <div
        class="relative py-8 bg-fixed bg-center bg-no-repeat bg-cover min-height:100vh"
        style="background-image: url(/img/bg-hero.webp)"
      >
        <div
          class="absolute inset-0 z-20 bg-center bg-no-repeat bg-cover bg-gradient-to-r from-hero-gradient-from to-hero-gradient-to"
        />

        <div class="container relative z-30 pt-20 pb-12 sm:pt-56 sm:pb-48 lg:pt-64 lg:pb-48">
          <div class="flex flex-col items-center justify-center lg:flex-row">
            <AuthorImage />
            <div class="pt-8 sm:pt-10 lg:pl-8 lg:pt-0">
              <AuthorGreeting />
              <div class="flex flex-col justify-center pt-3 sm:flex-row sm:pt-5 lg:justify-start">
                <div class="flex items-center justify-center pl-0 sm:justify-start md:pl-1">
                  <p class="text-lg text-white uppercase font-body">
                    Let's connect
                  </p>
                  <div class="hidden sm:block">
                    <i class="text-3xl bx bx-chevron-right text-primary" />
                  </div>
                </div>
                <div class="flex items-center justify-center pt-5 pl-0 sm:pl-2 sm:justify-start sm:pt-0">
                  <AuthorSocials
                    class="text-white hover:text-primary"
                    :socials="appConfig.socials as Social[]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="about"
      class="bg-grey-50"
    >
      <div class="container flex flex-col items-center py-16 md:py-20 lg:flex-row">
        <div class="w-full text-center sm:w-3/4 lg:pl-10 lg:w-4/5 lg:text-left">
          <h2 class="text-4xl font-semibold uppercase font-header text-primary sm:text-5xl lg:text-6xl">
            Who am I?
          </h2>
          <h4 class="pt-6 text-xl font-medium text-black font-header sm:text-2xl lg:text-3xl">
            I'm Daniel Hagen, a Technologist, Creator & Speaker
          </h4>
          <p class="pt-6 leading-relaxed font-body text-grey-20">
            I'm currently a Senior DevOps Engineer for a enterprise consulting firm, <a
              href="https://liatrio.com"
              class="text-primary"
            >Liatrio</a>, where I help companies transform their software delivery practices. I specialize in cloud-native
            technologies, DevOps, and automation. I'm a passionate advocate for the use of Open Source technologies and
            methodologies to improve the quality of software delivery and the lives of those who deliver it.
          </p>
          <p class="pt-6 leading-relaxed font-body text-grey-20">
            My last adventure was as the Director of IT for Aspyr Media, an Austin-based leading video game publishing and
            development partner focused on maximizing the potential of games across all platforms. I lead internal and
            external IT services, including enabling innovative technologies for customer service initiatives on a global
            scale, and for employees in their day-to-day work.
          </p>
          <p class="pt-6 leading-relaxed font-body text-grey-20">
            I have several industry and project management certifications and contribute to over 20 Open Source libraries,
            toolset, and systems images. I am a frequent speaker at AWS Summits, ServerlessConf, Open Cloud Foundation,
            ChefConf, AltConf, DevOpsDays. In addition, I am highly dedicated to the Austin technical community through
            meet-ups and training junior- and mid-level talent.
          </p>
          <p class="pt-6 leading-relaxed font-body text-grey-20">
            Prior to Aspyr, I held IT positions for Logitech and LifeSize Communications.
          </p>
        </div>
      </div>
    </div>

    <ContactForm
      id="contact"
      class="pt-16 md:pt-20"
    />
    <div>
      <div
        class="bg-fixed bg-center lg:bg-[center_bottom_-10rem] bg-no-repeat bg-cover min-height:100vh h-72 sm:h-64 md:h-72 lg:h-96"
        style="background-image: url(/img/map.webp)"
      />
      <!-- 742px tall image -->
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Social } from '@/components/AuthorSocials.vue'

const appConfig = useAppConfig()
</script>
